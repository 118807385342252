import axios from 'axios';
import AppInstance from '@/main';
import qs from 'qs';
import humps from 'humps';
import authApi from '@/api/auth';

const axiosApi = axios.create({
    baseURL: process.env.VUE_APP_API_URL || "https://api.agrosmart.dev/api/v1",
    headers: {
        common: {
            Authorization: `Bearer ${localStorage.getItem('Auth-Token')}`,
        },
    },
    paramsSerializer: (params) => qs.stringify(humps.decamelizeKeys(params), { arrayFormat: 'brackets' }),
    transformResponse: [
        ...axios.defaults.transformResponse,
        (data) => humps.camelizeKeys(data),
    ],
    transformRequest: [
        (data) => humps.decamelizeKeys(data),
        ...axios.defaults.transformRequest,
    ],
});

axiosApi.interceptors.request.use(
    function (config) {
        AppInstance.$bus.$emit('load', true);
        return config;
    },
    function (error) {
        AppInstance.$bus.$emit('load', false);
        return Promise.reject(error);
    });
axiosApi.interceptors.response.use(
    function (response) {
        AppInstance.$bus.$emit('load', false);
        return response;
    },
    async function (err) {
        AppInstance.$bus.$emit('load', false);

        if (err.response.status === 401) {
            const originalRequest = err.config;
            try {
                const { data } = await authApi.refreshToken(localStorage.getItem('Refresh-Token'));
                localStorage.setItem('Auth-Token', data.accessToken);
                localStorage.setItem('Refresh-Token', data.refreshToken);
                axiosApi.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
                return axiosApi(originalRequest);
            } catch (err) {
                console.error(err);
                localStorage.removeItem('Auth-Token');
                localStorage.removeItem('Refresh-Token');
                localStorage.removeItem('User');
                delete axiosApi.defaults.headers.common.Authorization;
                AppInstance.$router.push({ name: 'login' });
            }
        } else if (!err.response || err.response.status !== 422) {
            AppInstance.$notify({
                title: AppInstance.$t('error.connection'),
                type: 'error'
            })
        }
        return Promise.reject(err);
    });

export default axiosApi;
